import React, { Component } from "react";
import { Helmet } from "react-helmet";
import swal from "@sweetalert/with-react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getAdminInfo } from "./../../actions/usersAction";
import Wrapper from "./../../Components/Container";

import Spinner from "./../../Components/Spinner";
import avatar from "./../../assets/images/avatar.png";

import styles from "./style.module.scss";
const REACT_APP_BACKEND_URI = "https://jarasubapi.onrender.com"

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            email: "",
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            updatingPassword: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmitPassword = this.onSubmitPassword.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmitPassword = (e) => {
        this.setState({ updatingPassword: true });
        e.preventDefault();
        const data = {
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword,
        };
        if (data.confirmPassword !== data.newPassword) {
            this.setState({ updatingPassword: false });
            return swal("Error", "New password mis-match", "warning");
        }

        const api = `${REACT_APP_BACKEND_URI}/getAdminInfo/updatePassword`;
        const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;
        axios
            .post(api, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    swal(
                        "Completed",
                        "Password updated successfully",
                        "success"
                    );
                    this.setState({
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                        updatingPassword: false,
                    });
                } else {
                    swal("Oops", "Incorrect Password", "warning");
                    this.setState({ updatingPassword: false });
                }
            })
            .catch((err) => {
                swal("Oops", "Error updating password", "warning");
                this.setState({ updatingPassword: false });
            });
    };

    componentDidMount() {
        this.setState({
            userName: this.props.userInfo.userName,
            email: this.props.userInfo.email,
            phoneNumber: this.props.userInfo.phoneNumber,
        });
    }

    render() {
        return (
            <Wrapper>
                <Helmet>
                    <title>
                        Jarasub |{" "}
                        {this.state.userName
                            ? this.state.userName.toUpperCase()
                            : "MY PROFILE"}{" "}
                    </title>
                </Helmet>
                <div className={styles.card}>
                    <img src={avatar} alt="avatar" />
                    <div>
                        <h1>{this.props.userInfo.userName.toUpperCase()}</h1>
                        <p>{this.props.userInfo.email}</p>
                    </div>
                </div>

                <form className={styles.Form} onSubmit={this.onSubmitPassword}>
                    <h3>UPDATE PASSWORD</h3>
                    <label>Old Password</label>
                    <input
                        onChange={this.onChange}
                        type="password"
                        name="oldPassword"
                        id="oldPassword"
                        autoComplete="off"
                        placeholder="Old Password"
                        required={true}
                        value={this.state.oldPassword}
                        disabled={this.state.waiting}
                    />
                    <label>New Password</label>
                    <input
                        onChange={this.onChange}
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        autoComplete="off"
                        placeholder="New Password"
                        required={true}
                        value={this.state.newPassword}
                        disabled={this.state.waiting}
                    />
                    <label>Confirm Password</label>
                    <input
                        onChange={this.onChange}
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        autoComplete="off"
                        placeholder="Confirm Password"
                        required={true}
                        value={this.state.confirmPassword}
                        disabled={this.state.waiting}
                    />
                    <button
                        type="submit"
                        disabled={this.state.updatingPassword}
                    >
                        {this.state.updatingPassword ? (
                            <Spinner />
                        ) : (
                            "UPDATE PASSWORD"
                        )}
                    </button>
                </form>
            </Wrapper>
        );
    }
}

Index.propTypes = {
    userInfo: PropTypes.object.isRequired,
    getAdminInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.user.auth,
    userInfo: state.user.userInfo,
});

export default connect(mapStateToProps, { getAdminInfo })(Index);
