import React, { Component } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Wrapper from "./../../Components/Container";

import styles from "./style.module.scss";

const REACT_APP_BACKEND_URI = "https://jarasubapi.onrender.com";
// const REACT_APP_BACKEND_URI = "http://localhost:4000";

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            balance: 0,
        };
    }

    componentDidMount() {
        if (!sessionStorage.getItem("jara_sub"))
            return this.props.history.push("/");
        const api = `${REACT_APP_BACKEND_URI}/getUserInfo/balance`;
        const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;
        axios
            .get(api, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                console.log(res);
                this.setState({ balance: res.data.balance });
            }).catch(err => {
                console.error("Error Getting Data")
            })
    }
    render() {
        return (
            <Wrapper>
                <Helmet>
                    <title>Jarasub | DASHBOARD </title>
                </Helmet>
                <div className={styles.card}>
                    <div>
                        <h2>Wallet Balance</h2>
                        <p>As at 11/11/2020</p>
                    </div>
                    <div>
                        <h1>₦ {this.state.balance}</h1>
                    </div>
                </div>
                <div className={styles.card}>
                    <div>
                        <h2>Users Balance</h2>
                        <p>As at 11/11/2020</p>
                    </div>
                    <div>
                        <h1>₦ {this.state.balance}</h1>
                    </div>
                </div>
                <div className={styles.card}>
                    <div>
                        <h2>Transaction Funds</h2>
                        <p>As at 11/11/2020</p>
                    </div>
                    <div>
                        <h1>₦ {this.state.balance}</h1>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default index;
