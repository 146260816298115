import React, { Component } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Wrapper from "./../../Components/Container";
import Loader from "./loader";

import styles from "./../Transfer/style.module.scss";
import Swal from "sweetalert2";
import Toast from "../../Components/Toast";
const REACT_APP_BACKEND_URI = "https://jarasubapi.onrender.com"
// const REACT_APP_BACKEND_URI = "http://localhost:4000"

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      history: [],
      usersBalance: 0,
      premium: "rgb(0, 255, 106)",
      free: "#FEE440",
      loading: true,
    };
  }

  getUsers() {
    const api = `${REACT_APP_BACKEND_URI}/adminGetUsers`;
    const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;
    axios
      .get(api, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        let users = res.data.users;

        let totalBalance = 0;
        for (let index = 0; index < users.length; index++) {
          totalBalance += parseInt(users[index].balance);
        }

        this.setState({
          history: users,
          usersBalance: totalBalance,
          loading: res.data.error,
        });
      });
  }

  componentDidMount() {
    this.getUsers();
  }

  fundAccount(user) {
    Swal.fire({
      title: "Fund User account",
      input: "number",
      text: "Enter amount you want to fund."
    }).then(res => {
      if (res.isConfirmed && res.value) {
        const api = `${REACT_APP_BACKEND_URI}/adminfunding/fund`;
        const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;

        axios
        .post(api, {id: user._id, amount: res.value},
          { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }, 
        })
        .then((res) => {
          this.getUsers();
          return Toast.fire({
              icon: res.data.error ? "error" : "success",
              title: res.data.message,
          });
        }).catch(err => {
            Toast.fire({
            icon: "error",
            title: "An error occured",
          })

        });
      }
    })
  }


  debitAccount(user) {
    Swal.fire({
      title: "Debit User account",
      input: "number",
      text: "Enter amount you want to debit."
    }).then(res => {
      if (res.isConfirmed && res.value) {
        const api = `${REACT_APP_BACKEND_URI}/adminfunding/debit`;
        const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;

        axios
        .post(api, {id: user._id, amount: res.value},
          { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }, 
        })
        .then((res) => {
          this.getUsers();
          return Toast.fire({
              icon: res.data.error ? "error" : "success",
              title: res.data.message,
          });
        }).catch(err => {
            Toast.fire({
            icon: "error",
            title: "An error occured",
          })

        });
      }
    })
  }

  render() {
    return (
      <Wrapper>
        <Helmet>
          <title> LAB USERS </title>
        </Helmet>
        <form className={[styles.Form, styles.loose].join(" ")}>
          <h1>Users</h1>
          <h3> {this.state.history.length} User's Info</h3>
          <h3>Total Balance: {this.state.usersBalance}</h3>
        </form>
        {this.state.loading ? (
          <Loader />
        ) : this.state.history.length ? (
          this.state.history.reverse().map((trans, index) => {
            return (
              <div
                className={[styles.card, styles.loose].join(" ")}
                key={index}
              >
                <div className={[styles.infos].join(" ")}>
                  <h4>
                    {trans.email}/
                    <span className={styles.phoneNumber}>
                      {trans.phoneNumber}
                    </span>
                  </h4>
                  <p>{trans.reg_Date}</p>
                </div>
                <h2>
                  Name: <u>{trans.firstName[0].toUpperCase() + trans.firstName.slice(1)} {trans.lastName[0].toUpperCase() + trans.lastName.slice(1)}</u>
                </h2>
                <h2>
                  Username: <u>{trans.userName[0].toUpperCase() + trans.userName.slice(1)}</u>
                </h2>
                <div className={[styles.infos].join(" ")}>
                  <p>
                    <b>Balance:</b> {trans.balance}
                  </p>
                  
                </div>
                <div className={[styles.infos].join(" ")}>
                  <button 
                  style={{backgroundColor: "lightgreen", padding: "5px 10px", borderRadius: "5px", margin: "10px 0px"}}
                  onClick={() => this.fundAccount(trans)}
                  >Fund account</button>
                  <button 
                  style={{backgroundColor: "red", color: "white", padding: "5px 10px", borderRadius: "5px", margin: "10px 0px"}}
                  onClick={() => this.debitAccount(trans)}
                  >Debit account</button>
                  
                </div>

              </div>
            );
          })
        ) : (
          "No Transaction History"
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.user.auth,
});

export default connect(mapStateToProps, {})(Index);
