import React from "react";
import { Provider } from "react-redux";

import { Switch, Route } from "react-router-dom";

import Error from "./Routes/Error";
import Login from "./Routes/Forms/Login";

import FreeData from "./Routes/Manager/FreeData";
import PremiumData from "./Routes/Manager/PremiumData";
import TVAmount from "./Routes/Manager/TVAmount";

import Dashboard from "./Routes/Dashboard";
import Transfer from "./Routes/Transfer";
import Profile from "./Routes/Profile";
import Airtime from "./Routes/Airtime";
import History from "./Routes/History";
import Messages from "./Routes/Messages";
import Users from "./Routes/Users";

import store from './store'

function App(props) {
    return (
        <Provider store={store}>
            <Switch>
                <Route path="/" component={Login} exact></Route>
                <Route path="/free-data-manager" component={FreeData} exact></Route>
                <Route path="/premium-data-manager" component={PremiumData} exact></Route>
                <Route path="/tv-amount-manager" component={TVAmount} exact></Route>
                <Route path="/data-prices" component={FreeData} exact></Route>
                <Route path="/dashboard" component={Dashboard} exact></Route>
                <Route path="/user-profile" component={Profile} exact></Route>
                <Route path="/airtime-topup" component={Airtime} exact></Route>
                <Route path="/history" component={History} exact></Route>
                <Route path="/users" component={Users} exact></Route>
                <Route path="/messages" component={Messages} exact></Route>
                <Route path="/transfer-fund" component={Transfer} exact></Route>

                <Route path="*" component={Error} exact></Route>
            </Switch>
        </Provider>
    );
}

export default App;
