import React, { Component } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Wrapper from "./../../Components/Container";
import Loader from "./loader";

import styles from "./../Transfer/style.module.scss";
const REACT_APP_BACKEND_URI = "https://jarasubapi.onrender.com"

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            history: [],
            fullHistory: [],
            true: "rgb(0, 255, 106)",
            false: "#FEE440",
            loading: true,
            quantity: 200,
        };
    }

    componentDidMount() {
        const api = `${REACT_APP_BACKEND_URI}/adminGetPayment`;
        const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;
        axios
            .get(api, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data);
                res.data.reverse()
                this.setState({
                    fullHistory: res.data.reverse(),
                    history: res.data.reverse().slice(
                        0,
                        res.data.length <= 10
                            ? res.data.length
                            : this.state.quantity
                    ),
                    quantity:
                        res.data.length % 10 > 1 ? res.data.length + 10 : 10,
                    loading: false,
                });
            }).catch(err => {
                console.log("Error in history =>", err);
            });
    }

    fetchMoreData = () => {
        setTimeout(() => {
            this.setState({
                history: this.state.fullHistory.slice(
                    0,
                    this.state.fullHistory.length <= 10
                        ? this.state.fullHistory.length
                        : this.state.quantity
                ),
                quantity:
                    this.state.fullHistory.length % 10 > 1
                        ? this.state.fullHistory.length + 10
                        : this.state.quantity,
            });
        }, 1500);
    };

    getStatus = (obj) => {
        Swal.fire({
            title: "Payment Info",
            text: "You won't be able to revert this!",
            html: `<div><p style="display:flex;">Amount:-- <b>₦${obj.amount}</b></p><p style="display:flex;">Username:-- <b>${obj.userName}</b></p><p style="display:flex;">Date:-- <b>${obj.date}</b><p style="display:flex;">Status:-- <b>${obj.status}</b></div>`,
            backdrop: "#00000090",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Okay",
        })
    }

    render() {
        return (
            <Wrapper>
                <Helmet>
                    <title> Transaction HISTORY </title>
                </Helmet>
                <form className={[styles.Form, styles.loose].join(" ")}>
                    <h1>Transactions</h1>
                    {/* <h3>User Pays</h3> */}
                </form>
                {this.state.loading ? (
                    <Loader />
                ) : this.state.history.length ? (
                    this.state.history.map((trans, index) => {
                        return (
                            <div
                                className={[styles.card, styles.loose].join(
                                    " "
                                )}
                                key={index}
                                onClick={() => this.getStatus(trans)}
                            >
                                <div className={[styles.infos].join(" ")}>
                                    <h4>
                                        {trans.type}
                                        {trans.id ? (
                                            <small>( {trans.id} )</small>
                                        ) : null}
                                    </h4>
                                    <p>{trans.date}</p>
                                </div>
                                <h2>₦{parseFloat(trans.amount)}</h2>
                                <div className={[styles.infos].join(" ")}>
                                    <p>
                                        <b>User:</b>{" "}
                                        {trans.userName[0].toUpperCase() +
                                            trans.userName.slice(1)}
                                    </p>
                                    <p
                                        className={styles.success}
                                        style={{
                                            backgroundColor: trans.status === "success" ? "lightgreen" : "red",
                                        }}
                                    >
                                        {trans.status}
                                    </p>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    "No Transaction History"
                )}
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.user.auth,
});

export default connect(mapStateToProps, {})(Index);
