import React, { Component } from "react";
import Wrapper from "./../../../Components/Container";
import styles from "./../../Transfer/style.module.scss";
import axios from "axios";

import Spinner from "./../../../Components/Spinner";
import Swal from "sweetalert2";
const REACT_APP_BACKEND_URI = "https://jarasubapi.onrender.com"

class Index extends Component {
    constructor() {
        super();
        this.state = {
            waiting: false,
            prices: [],
            loading: false,
            clikedBtn: ""
        };
    }

    componentDidMount() {
        const api = `${REACT_APP_BACKEND_URI}/adminGetDataPrices`;
        const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;
        
        axios
            .post(api, {}, { headers: { "authorization": `Bearer ${token}`} })
            .then((res) => {
                this.setState({
                    prices: res.data.prices
                });
            }).catch(err => {
                console.log(err, "error");
            });
    }

    changePrice(e, subid) {
        const currentPrice = this.state.prices.find(price => price.subid === subid);
        currentPrice.price = parseInt(e.target.value);
        this.setState({prices: this.state.prices});
    }

    updatePrice = (subid, id) => {
        this.setState({loading: true, clikedBtn: subid});
        const prices = this.state.prices.find(price => price.subid === subid);
        const api = `${REACT_APP_BACKEND_URI}/adminUpdateDataPrices`;
        const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;

        axios
            .post(api, {subid, id, price: prices.price}, { headers: { "authorization": `Bearer ${token}`} })
            .then((res) => {
                this.setState({loading: false});
                if(res.data.error) {
                    return Swal.fire({icon: "error", title: "Error", text: res.data.message});
                }
                return Swal.fire({icon: "success", title: "Success", text: "Subscription price updated successfully!"}).then(() => {
                    
                    const api = `${REACT_APP_BACKEND_URI}/adminGetDataPrices`;
                    const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;
                    axios
                    .post(api, {}, { headers: { "authorization": `Bearer ${token}`} })
                    .then((res) => {
                        this.setState({
                            prices: res.data.prices
                        });
                    }).catch(err => {
                        console.log(err, "error");
                    })
                });
            }).catch(err => {
                this.setState({loading: false});
                console.log(err, "error");
                return Swal.fire({icon: "error", title: "Error", text: "Network error"});
            });

    }

    render() {
        return (
            <Wrapper>
                <div className={styles.Form}>
                    <h1>Data Manager</h1>
                    <table>
                        <thead className={styles.tableHead}>
                            <tr>
                                <td>Sub ID</td>
                                <td>Network</td>
                                <td>Value</td>
                                <td>Type</td>
                                <td>Price</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        {
                            this.state.prices &&
                            <tbody>
                                {
                                    this.state.prices.map(sub => {
                                        return (
                                        <tr>
                                            <td>{sub.subid}</td>
                                            <td>{sub.network}</td>
                                            <td>{sub.name}</td>
                                            <td>{sub.type}</td>
                                            <td>
                                                ₦<input type="number" value={this.state.prices.find(price => price.subid === sub.subid)?.price} onChange={(e) => this.changePrice(e, sub.subid)} />
                                            </td>
                                            <td>
                                                <button onClick={() => this.updatePrice(sub.subid, sub._id)} style={{padding: "5px", fontSize: "0.8rem", width: "70px"}}>{(this.state.loading && this.state.clikedBtn === sub.subid) ? <Spinner/> : "Update"}</button>
                                            </td>
                                        </tr>
                                        )
                                    })
                                }
                                {/* <tr>
                                    <td>500 MB</td>
                                    <td>₦200</td>
                                    <td>
                                        <input type="number" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>500 MB</td>
                                    <td>₦200</td>
                                    <td>
                                        <input type="number" />
                                    </td>
                                </tr> */}
                            </tbody>
                        }
                    </table>
                    {/* <button type="submit" disabled={this.state.waiting}>
                        {this.state.waiting ? <Spinner /> : "UPDATE"}
                    </button> */}
                </div>
            </Wrapper>
        );
    }
}

export default Index;
