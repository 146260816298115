import React, { Component } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { authUser, getAdminInfo } from "./../../../actions/usersAction";
import Navbar from "./../../../Components/Navbar";
import Spinner from "./../../../Components/Spinner";
import styles from "./../style.module.scss";

const REACT_APP_BACKEND_URI = "https://jarasubapi.onrender.com";
// const REACT_APP_BACKEND_URI = "http://localhost:4000";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            errorMessage: "",
            waiting: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = {
            userName: this.state.username,
            password: this.state.password,
        };
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });

        if (!data.userName) {
            return this.error("Username is required");
        } else if (!data.password) {
            return this.error("Password is required");
        } else {
            this.setState({ waiting: true });
            axios
                .post(`${REACT_APP_BACKEND_URI}/adminLogin`, data)
                .then((response) => {
                    if (response.data.errorMsg) {
                        this.setState({ waiting: false });
                        return this.error("Invalid username or password");
                    } else {
                        sessionStorage.setItem(
                            "jara_sub",
                            JSON.stringify({ token: response.data.accessToken })
                        );
                        this.props.authUser();
                        this.props.getAdminInfo();
                        Toast.fire({
                            icon: "success",
                            title: "Signed in successfully",
                        });
                        return this.props.history.push("/users");
                    }
                })
                .catch((error) => {
                    this.setState({ waiting: false });
                    return this.error(
                        "Error Fetching user info, Kindly try again"
                    );
                });
        }
    };

    error = (message) => {
        this.setState({ errorMessages: message });
        this.errorAlert(message);
    };

    errorAlert = (message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: "error",
            title: message,
        }).then(() => {
            this.setState({ errorMessages: "" });
        });
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>Jarasub |ADMIN LOGIN</title>
                </Helmet>
                <Navbar />
                <div className={styles.FormContainer}>
                    <h2 className={styles.title}>
                        <Link to="/">
                            Jara<span style={{ color: "#EF233C" }}>Sub</span>
                        </Link>
                    </h2>
                    <span>ADMINISTRATOR</span>
                    <form onSubmit={this.onSubmit} className={styles.form}>
                        <label>Username</label>
                        <input
                            onChange={this.onChange}
                            type="text"
                            name="username"
                            id="username"
                            placeholder="Username"
                            autoComplete="off"
                            value={this.state.email}
                            required={true}
                            disabled={this.state.waiting}
                        />
                        <label>Password</label>
                        <input
                            onChange={this.onChange}
                            type="password"
                            name="password"
                            id="password"
                            placeholder="▪▪▪▪▪▪▪▪▪▪▪▪"
                            value={this.state.password}
                            required={true}
                            disabled={this.state.waiting}
                        />
                        <button type="submit" disabled={this.state.waiting}>
                            {this.state.waiting ? <Spinner /> : "LOGIN"}
                        </button>
                    </form>
                    <p style={{ textAlign: "center", margin: "2em auto 2em" }}>
                        ©2022 Copyright Jarasub. <br />
                        All Rights Reserved.
                    </p>
                </div>
            </>
        );
    }
}

Index.propsTypes = {
    authUser: PropTypes.func.isRequired,
    getAdminInfo: PropTypes.func.isRequired,
};

export default connect("", {
    authUser,
    getAdminInfo,
})(Index);
