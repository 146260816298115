

import axios from "axios";
import {
    SET_AUTH,
    REMOVE_AUTH,
    GET_USER_INFO,
} from "./types";

const REACT_APP_BACKEND_URI = "https://jarasubapi.onrender.com"
// const REACT_APP_BACKEND_URI = "http://localhost:4000"

export const authUser = (data) => (dispatch) => {
    dispatch({
        type: SET_AUTH,
        payload: true,
    });
};

export const revokeUser = (data) => (dispatch) => {
    dispatch({
        type: REMOVE_AUTH,
        payload: false,
    });
};

export const getAdminInfo = () => (dispatch) => {
    const api = `${REACT_APP_BACKEND_URI}/getAdminInfo`;
    const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;
    axios
        .get(api, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
            dispatch({
                type: GET_USER_INFO,
                payload: res.data,
            });
        });
};
