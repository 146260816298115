import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import avatar from "./../../assets/images/av2.png";
import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./style.module.scss";

class Index extends Component {
    logout() {
        sessionStorage.removeItem("jara_sub");
    }

    render() {
        return (
            <div
                className={[
                    styles.sidebar,
                    this.props.visible
                        ? styles.visibleNav
                        : styles.inVisibleNav,
                ].join(" ")}
            >
                <nav>
                    <ul>
                        <li className={styles.preview}>
                            <img src={avatar} alt="avatar" />
                            <div>
                                <h4>
                                    {this.props.userInfo.userName.toUpperCase() ? this.props.userInfo.userName.toUpperCase() : "LAB ADMIN"}
                                </h4>
                                <p
                                    style={{
                                        textAlign: "center",
                                        backgroundColor: "#001A23",
                                        borderRadius: "25px",
                                        color: "#FFFFFF",
                                        fontWeight: ".1em",
                                        fontSize: ".7em",
                                    }}
                                >
                                    LAB ADMIN
                                </p>
                            </div>
                        </li>
                        <NavLink
                            to="/user-profile"
                            exact
                            activeClassName={styles.active}
                        >
                            <li>USER PROFILE</li>
                        </NavLink>
                        {/* <NavLink
                            to="/dashboard"
                            exact
                            activeClassName={styles.active}
                        >
                            <li>DASHBOARD</li>
                        </NavLink> */}
                        <NavLink
                            to="/users"
                            exact
                            activeClassName={styles.active}
                        >
                            <li>USERS</li>
                        </NavLink>
                        {/* <NavLink
                            to="/messages"
                            exact
                            activeClassName={styles.active}
                        >
                            <li>MESSAGES</li>
                        </NavLink> */}
                        {/* <NavLink
                            to="/airtime-topup"
                            exact
                            activeClassName={styles.active}
                        >
                            <li>AIRTIME TOP-UP</li>
                        </NavLink> */}
                        {/* <NavLink
                            to="/transfer-fund"
                            exact
                            activeClassName={styles.active}
                        >
                            <li>FUND CLIENT</li>
                        </NavLink> */}
                        <NavLink
                            to="/data-prices"
                            exact
                            activeClassName={styles.active}
                        >
                            <li>DATA PRICES</li>
                        </NavLink>
                        <NavLink
                            to="/history"
                            exact
                            activeClassName={styles.active}
                        >
                            <li>TRANSACTION HISTORY</li>
                        </NavLink>
                        <NavLink
                            to="/"
                            exact
                            activeClassName={styles.active}
                            onClick={this.logout}
                        >
                            <li>LOGOUT</li>
                        </NavLink>
                    </ul>
                </nav>
            </div>
        );
    }
}

Index.propTypes = {
    userInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
});

export default connect(mapStateToProps, {})(withRouter(Index));
