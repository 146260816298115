import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Wrapper from "./../../Components/Container";
import Loader from "./loader";

import styles from "./../Transfer/style.module.scss";
const REACT_APP_BACKEND_URI = "https://jarasubapi.onrender.com"

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            history: [],
            premium: "rgb(0, 255, 106)",
            free: "#FEE440",
            loading: true,
        };
    }

    componentDidMount() {
        const api = `${REACT_APP_BACKEND_URI}/adminGetMessages`;
        const token = JSON.parse(sessionStorage.getItem("jara_sub")).token;
        axios
            .get(api, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data);
                this.setState({
                    history: res.data,
                    loading: false,
                });
            });
    }

    render() {
        return (
            <Wrapper>
                <form
                    className={[styles.Form, styles.loose].join(" ")}
                    style={{ paddingTop: "1em", paddingBottom: "1em" }}
                >
                    <h1 style={{ margin: "0" }}>Messages</h1>
                </form>
                {this.state.loading ? (
                    <Loader />
                ) : this.state.history.length ? (
                    this.state.history.reverse().map((trans, index) => {
                        return (
                            <div className={styles.card} key={index}>
                                <h4>
                                    {trans.messageType[0].toUpperCase() +
                                        trans.messageType.slice(1)}
                                </h4>
                                <p>{trans.message}</p>
                                <span>
                                    {trans.userName[0].toUpperCase() +
                                        trans.userName.slice(1)}{" "}
                                </span>{" "}
                                - ({trans.email})
                            </div>
                        );
                    })
                ) : (
                    "No Transaction History"
                )}
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.user.auth,
});

export default connect(mapStateToProps, {})(Index);
