import React from "react";

import styles from "./style.module.scss";

function Index(props) {
    return (
        <div className={styles.MainNav}>
            <h2>
                Jara<span className={styles.yellow}>Sub ADMIN</span>ISTRATOR
            </h2>
        </div>
    );
}

export default Index;
