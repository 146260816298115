import React, { Component } from "react";
import Wrapper from "./../../../Components/Container";

class index extends Component {
    render() {
        return (
            <Wrapper>
                <div>PremiumData</div>
            </Wrapper>
        );
    }
}

export default index;
